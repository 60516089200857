import {Modal} from "ant-design-vue";
import EmptyUtil from "@/utils/common/EmptyUtil";

/**
 * 确认弹窗，async await方式调用，
 * @param title
 * @param content
 * @param cancelText
 * @param okText
 * @returns {Promise<unknown>} 返回 true：确认，false：取消
 */
export const modalConfirm = (title, content, cancelText, okText) => {
    return new Promise((resolve, reject) => {
        let params = {};
        if (!EmptyUtil.isEmpty(title)) {
            params.title = title;
        }
        if (!EmptyUtil.isEmpty(content)) {
            params.content = content;
        }
        params.cancelText = cancelText || '取消';
        params.okText = okText || '确认';

        params.onOk = () => {
            resolve(true);
        }
        params.onCancel = () => {
            resolve(false);
        }
        Modal.confirm(params);
    })
}