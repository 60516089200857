<template>

  <el-dialog :value="value" top="3vh" width="70%" :title="'对话详情'+(rowJson.read?'-已读':'-未读')" :show-close="false"
             :destroy-on-close="true">
    <!-- 1.主体 -->
    <div class="flex-nowrap-start height-min">
      <!-- 1.1左边列表 -->
      <div class="mr-15">
        <div class="flex-nowrap-flex-start">
          <div class="left-title">服务商：</div>
          <div class="left-text">{{ rowJson.amountName ? rowJson.amountName : '--' }}</div>
        </div>
        <div v-if="!needHideKeyValue"
             class="flex-nowrap-flex-start">
          <div class="left-title">主叫所属用户：</div>
          <div class="left-text">{{ rowJson.nickName ? rowJson.nickName : rowJson.company }}</div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div class="left-title">主叫：</div>
          <div class="left-text flex-nowrap-space-between">
            <div>{{ telADesc }}</div>
            <el-button class="mr-10" type="danger" size="mini" v-if="!rowJson.telADisabled && isAdminUser=='system'"
                       @click="bannedUserDialog=true">禁用
            </el-button>
            <el-tag v-if="rowJson.telADisabled" class="mr-10" type="danger">已禁用</el-tag>
          </div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div class="left-title">小号：</div>
          <div class="left-text">{{ telXDesc }}</div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div class="left-title">被叫：</div>
          <div class="left-text flex-nowrap-space-between">
            <div>{{ telBDesc }}</div>
            <el-button class="mr-10" type="danger" size="mini" v-if="!rowJson.telBDisabled && isAdminUser=='system'"
                       @click="bannedUserOther(rowJson)">禁用
            </el-button>
            <el-tag v-if="rowJson.telBDisabled" class="mr-10" type="danger">已禁用</el-tag>
          </div>
        </div>

        <div class="flex-nowrap-flex-start">
          <div class="left-title">通话时长：</div>
          <div class="left-text">{{ rowJson.duration ? (rowJson.duration ) : '0' }}</div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div class="left-title">通话时间：</div>
          <div class="left-text">{{ rowJson.stratTime }}</div>
        </div>
        <div v-if="type=='apiRule'" class="flex-nowrap-flex-start">
          <div class="left-title">违规等级：</div>
          <div class="left-text-mid">
            <el-select v-model="rowJson.risk" placeholder="请选择违规等级" @change="handleRuleTypeChange">
              <el-option
                  v-for="(item, index) in ruleTypeDescList"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--            <a-radio-group v-model:value="rowJson.risk" button-style="solid"-->
            <!--                           :style="{margin: 'auto', marginBottom: '5px'}"-->
            <!--                           @change="handleRiskChange">-->
            <!--              &lt;!&ndash;            <a-radio-button value="">全部</a-radio-button>&ndash;&gt;-->
            <!--              <a-radio-button v-for="item in ruleTypeDescList" :value="item.value" :key="'risk'+item.value">{{-->
            <!--                  item.label-->
            <!--                }}-->
            <!--              </a-radio-button>-->
            <!--            </a-radio-group>-->
          </div>
        </div>
        <!--        <div class="flex-nowrap-flex-start">-->
        <!--          <div class="left-title">准入行业：</div>-->
        <!--          <div class="left-text">{{-->
        <!--              rowJson.industry || '&#45;&#45;'-->
        <!--            }}-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="flex-nowrap-flex-start">
          <div class="left-title">命中类型：</div>
          <div class="left-text">{{ rowJson.hitType }}</div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div :class="hitTypeDescList&&hitTypeDescList.length>1?'left-title-other':'left-title'">命中关键字：</div>
          <div :class="hitTypeDescList&&hitTypeDescList.length>1?'left-text-other overflow-y':'left-text'">
            <el-tag class="right-width" v-for="(item,i) in hitTypeDescList" :key="i">{{ item }}</el-tag>
          </div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div class="left-title">报备行业：</div>
          <div class="left-text">
            {{ rowJson.industry }}
          </div>
        </div>
        <div class="flex-nowrap-flex-start">
          <div
              :class="rowJson.template&&rowJson.template.length>15?'left-title-other':'left-title'">
            报备话术：
          </div>
          <div
              :class="rowJson.template&&rowJson.template.length>15?'left-text-other overflow-y':'left-text'">
            {{ rowJson.template }}
          </div>
        </div>
        <div class="bottom-box flex-nowrap-center-center">
          <audio id="audio" class="recordSon-audio" :src="rowJson.recordUrl" controls="controls"></audio>
        </div>
      </div>
      <!-- 1.2右边对话 -->
      <div class="talkBox">
        <div v-for="(oitem,o) in translatedContent" :key="o">
          <div class="talkBox-item" v-show="oitem.channel_id == 0">
            <!--            <div class="flex-nowrap-flex-start">-->
            <!--              <div class="text-align-left">主叫</div>-->
            <!--              <SoundOutlined style="margin-left: 5px"/>-->
            <!--            </div>-->
            <div class="text-align-left">主叫</div>
            <div class="flex-nowrap-flex-start relative-box">
              <div>
                <el-avatar :size="40" :src="require(`@/assets/images/telA.png`)"></el-avatar>
              </div>
              <div class="triangle"></div>
              <div class="ml-15 telA-box" style="max-width: 60%" @click="positionAudio(oitem)"
                   v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
            </div>
          </div>
          <div class="talkBox-item" v-show="oitem.channel_id == 1">
            <!--            <div class="flex-nowrap-flex-end">-->
            <!--              <SoundOutlined rotate="180" style="margin-right: 5px"/>-->
            <!--              <div class="text-align-right">被叫</div>-->
            <!--            </div>-->
            <div class="text-align-right">被叫</div>
            <div class="flex-nowrap-flex-end relative-box">
              <div class="mr-15 telB-box" style="max-width: 60%" @click="positionAudio(oitem)"
                   v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
              <div class="triangle-other"></div>
              <div>
                <el-avatar :size="40" :src="require(`@/assets/images/telB.png`)"></el-avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2.底部操作 -->
    <div class="flex-nowrap-space-between mt-10">
      <div>
        <div v-if="showBottomHandle&&audit">
          <label style="margin-right: 10px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px"
                 :class="auditStatusClass">{{ auditStatusDesc }}</label>
          <label style="margin-right: 5px;">审核结果</label>
          <a-radio-group
              name="radioGroup"
              v-model:value="auditStatus">
            <a-radio :value="1">正常</a-radio>
            <a-radio :value="2">违规</a-radio>
            <a-radio :value="3">误判</a-radio>
            <a-radio :value="4">复审</a-radio>
            <a-radio :value="0">未审核</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div>
        <el-button v-if="showBottomHandle" type="primary" plain @click="copyMessage">复制质检记录</el-button>
        <el-button v-if="showBottomHandle" type="primary" plain @click="handelUP" :loading="btnLoading">上一个
        </el-button>
        <el-button v-if="showBottomHandle" type="primary" plain @click="handelNext" :loading="btnLoading">下一个
        </el-button>
        <el-button v-if="showBottomHandle&&audit" type="primary" plain @click="handelAutd" :loading="btnLoading">确认
        </el-button>
        <el-button v-if="showAddWorkOrder" type="primary" plain @click="addWorkOrder">添加工单</el-button>
        <el-button @click="handelClose">关闭</el-button>
      </div>
    </div>

    <el-dialog v-model="bannedUserDialog" title="将封禁该号码, 是否继续?"
               append-to-body
               :show-close="false"
               :destroy-on-close="true">
      <el-form ref="formRef" layout="horizontal" :model="bannedForm"
               label-width="100px">
        <el-form-item label="主叫：" class="m3-margin-bottom-m">
          {{ telADesc }}
        </el-form-item>
        <el-form-item label="服务商：" class="m3-margin-bottom-m">
          <!--          {{ rowJson.amountName }}-->
          <el-select v-model="bannedForm.amountId" placeholder="请选择服务商" filterable clearable
                     :options="amountList"
                     ref="select">
            <el-option :value="item.value" v-for="item in amountList"
                       :label="item.label"
                       :key="item.value">{{
                item.label
              }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="禁用时长:" name="disableDuration">
          <el-select v-model="bannedForm.disableDuration" placeholder="请选择禁用时长">
            <el-option
                v-for="(item, index) in disableDurationList"
                :key="item.label"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因:" name="reason">
          <el-select v-model="bannedForm.reason" placeholder="请选择原因" :style="{width:'100%'}">
            <el-option
                v-for="(item, index) in bannedReasonList"
                :key="item.label"
                :label="item.label"
                :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" name="remark">
          <el-input class="m3-width-full" v-model="bannedForm.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="m3-flex m3-justify-content-end">
        <el-button plain @click="bannedUserDialog=false">取消</el-button>
        <el-button type="primary" :loading="bannedUserDialogLoading" @click="bannedUser">确定</el-button>
      </div>
    </el-dialog>

  </el-dialog>


</template>
<script>
import _lineService from "@/api/open/LineQualityInspections";
import {ElMessage} from 'element-plus'
import {
  SoundOutlined,
} from '@ant-design/icons-vue';
import industryRequest from "@/api/settings/industry";
import {httpEnv} from "@/utils/config";
import {safePromise} from "@/utils/common/safePromise";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {modalConfirm} from "@/utils/common/modalConfirm";

export default {
  components: {
    // SoundOutlined,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    type: {
      default: 'other',//'apiRule':api质检记录,'lineRule':线路质检记录,'other'
    },
    rowJson: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isAdminUser: {
      type: String,
      default: () => {
        return ''
      }
    },
    showBottomHandle: {
      //显示底部操作按钮
      type: Boolean,
      default: () => true
    },
    showAddWorkOrder: {
      //显示底部创建工单操作按钮
      type: Boolean,
      default: () => false
    },
    audit: {
      type: Boolean,
      default: () => true
    },
    getParams: {
      type: Object,
      default: () => ({})
    },
    ruleType: {
      type: Object,
      default: () => {
        {
          ''
        }
      }
    },
    amountOption: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      hitTypeDescList: [],
      translatedContent: [],
      // industry: '',
      // textarea1641434070934: '',
      btnLoading: false,
      auditStatus: 1,//默认正常
      riskOption: [
        {label: '高', value: 3},
        {label: '中', value: 2},
        {label: '低', value: 1},
        {label: '无', value: 0},
      ],
      ruleTypeDescList: [
        // {label: '全部', value: '', desc: '0/0', labelColor: '#fff0f0'},
        {label: '特大违规', value: 1,},
        {label: '重大违规', value: 2,},
        {label: '一般违规', value: 3,},
        {label: '轻微违规', value: 4,},
        // {label: '无违规', value: 0,},
      ],
      disableDurationList: [
        {label: '4小时', value: 4,},
        {label: '8小时', value: 8,},
        {label: '12小时', value: 12,},
        {label: '24小时', value: 24,},
        {label: '48小时', value: 48,},
        {label: '永久', value: '',},
      ],
      bannedReasonList: [
        {label: '因拨打话务与报备话术不一致，但不涉及禁入场景，主叫被临时禁用，请联系平台客服处理。', value: 1,},
        {label: '因涉及辱骂客户或与客户对骂等场景，主叫被临时禁用，请联系平台客服处理。', value: 2,},
        {label: '因拨打话务涉及平台禁入场景，主叫已被禁用，请联系平台客服处理。', value: 3,},
        {label: '因拨打话务涉及该线路禁止提及的关键词，主叫已被禁用，请联系平台客服处理。', value: 4,},
      ],
      bannedForm: {
        amountId: '0',
        disableDuration: null,//null表示没选，’‘空字符串表示永久
        reason: '',
        remark: '',
      },
      bannedUserDialog: false,
      bannedUserDialogLoading: false,
    }
  },
  unmounted() {
    if (this.audioTimeOut) {
      clearTimeout(this.audioTimeOut);
    }
  },
  watch: {
    rowJson(newV, preV) {
      //上一条，下一条时button loading，获取到新值后取消loading
      this.btnLoading = false;
    },
  },
  computed: {
    needHideKeyValueRoleId() {
      let env = httpEnv();
      if (env == 'dev') {
        return 7;
      } else if (env == 'test') {
        return 7;
      }
      return 16;
    },
    needHideKeyValue() {
      //部分关键值不能被看到（目前对部分兼职生效）：渠道隐藏、企业隐藏、主叫脱敏、被叫脱敏
      return this.$store.state.user?.roleId == this.needHideKeyValueRoleId;
      // return true;
    },
    telADesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telA && this.rowJson?.telA.length >= 11 ? this.rowJson.telA.replace(this.rowJson.telA.substring(3, 7), "****") : this.rowJson?.telA;
      // }
      return this.rowJson?.telA;
    },
    telBDesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telB && this.rowJson?.telB.length >= 11 ? this.rowJson.telB.replace(this.rowJson.telB.substring(3, 7), "****") : this.rowJson?.telB;
      // }
      return this.rowJson?.telB;
    },
    telXDesc() {
      // if (this.needHideKeyValue) {
      //   return this.rowJson?.telX && this.rowJson?.telX.length >= 11 ? this.rowJson.telX.replace(this.rowJson.telX.substring(3, 7), "****") : this.rowJson?.telX;
      // }
      return this.rowJson?.telX;
    },
    amountList() {
      let res = [{label: '全部', value: '0'}];
      if (this.amountOption) {
        res.splice(res.length, 0, ...this.amountOption);
      }
      return res;
    },
    auditStatusDesc() {
      if (this.rowJson.auditStatus == '1') {
        return '正常'
      } else if (this.rowJson.auditStatus == '2') {
        return '违规'
      } else if (this.rowJson.auditStatus == '3') {
        return '误判'
      } else if (this.rowJson.auditStatus == '4') {
        return '复审'
      } else if (this.rowJson.auditStatus == '0') {
        return '未审核'
      }
      return '未知'
    },
    auditStatusClass() {
      if (this.rowJson.auditStatus == '1') {
        return 'audit-status-none'
      } else if (this.rowJson.auditStatus == '2') {
        return 'audit-status-highest'
      } else if (this.rowJson.auditStatus == '3') {
        return 'audit-status-high'
      } else if (this.rowJson.auditStatus == '4') {
        return 'audit-status-mid'
      } else if (this.rowJson.auditStatus == '0') {
        return 'audit-status-low'
      }
      return 'audit-status-low'
    },
  },
  methods: {
    //定位当前语音位置并播放
    positionAudio(sentence) {
      // play()开始播放音频
      // pause()暂停当前播放的音频
      // load()重新加载音频元素
      // addTextTrack()在音频中添加一个新的文本轨道
      // fastSeek()在音频播放器中指定播放时间。
      // canPlayType()检查浏览器是否可以播放指定的音频类型
      // getStartDate()返回一个新的Date对象，表示当前时间轴偏移量
      let audio = document.getElementById("audio");
      if (null != audio && sentence.begin_time) {
        audio.currentTime = sentence.begin_time / 1000;//设置从哪里开始播放
        audio.play();
        let duration = sentence.end_time - sentence.begin_time;//结束时间减去开始时间就是时长，结合setTimeout实现只播放当前一段话
        this.audioTimeOut = setTimeout(() => {
          if (null != audio) {
            audio.pause();
          }
        }, duration);
      }
    },
    async handleRuleTypeChange(value) {
      console.log('handleRuleTypeChange', value)
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: value
        })
        ElMessage({
          message: '修改成功',
          type: 'success',
        })

        this.rowJson.risk = value;
        let values = {risk: this.rowJson.risk};
        // this.$emit('successDDD')
        // this.$emit('nextData', this.rowJson)
        this.handelChange(0, values);
      } catch (err) {
        console.log(err);
      }
    },
    async handleRiskChange(e) {
      let targetRisk = e.target.value;
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: targetRisk
        })
        ElMessage({
          message: '修改成功',
          type: 'success',
        })

        this.rowJson.risk = targetRisk;
        // this.$emit('successDDD')
        this.$emit('nextData', this.rowJson)
      } catch (err) {
        console.log(err);
      }
    },
    async open() {
      this.hitTypeDescList = []

      // const req = await _lineService._recordService.reportIndustry({
      //   mobile: this.rowJson.telA,
      //   lineId: this.rowJson.amountId
      // })
      // this.industry = req.industry //报备行业
      // this.textarea1641434070934 = req.textarea1641434070934 //报备行业话术
      if (this.rowJson.hitTypeDesc) {
        this.hitTypeDescList = this.rowJson.hitTypeDesc.split(',')
      }

      this.bannedForm.amountId = this.rowJson.amountId ? `${this.rowJson.amountId}` : '0';
      if (this.rowJson.businessType) {//行业id
        //根据行业id查找行业话术
        let res = await industryRequest.queryById({id: this.rowJson.businessType});
        this.rowJson.template = res?.data?.template;
      }
      let translatedContentSentences;
      if (this.rowJson.translatedContent) {
        translatedContentSentences = JSON.parse(this.rowJson.translatedContent).payload?.sentences;
      }
      if (!translatedContentSentences) {
        const res = await _lineService._recordService.getByCheckId({checkId: this.rowJson.id})
        translatedContentSentences = JSON.parse(res.data.translatedContent).payload?.sentences;
      }
      this.translatedContent = translatedContentSentences || [];
      // this.readyToCopy = `线路名称：${this.rowJson.cmV1 || '--'}\r\n机构名称：${this.rowJson.cmV2 || '--'}\r\n主叫：${
      this.readyToCopy = `线路名称：${this.rowJson.amountName || '--'}\r\n${this.needHideKeyValue ? '' : '机构名称：'}${this.needHideKeyValue ?
          '' : (this.rowJson.nickName || this.rowJson.company || '--')}${this.needHideKeyValue ? '' : '\r\n'}主叫：${
          this.telADesc || '--'
      }\r\n被叫：${
          this.rowJson?.telB || '--'
      }\r\n通话时间：${this.rowJson.stratTime || '--'}\r\n命中类型：${
          this.rowJson.hitType || '--'
      }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
          this.rowJson.recordUrl || '--'
      }`

    },
    async bannedUser() {
      // this.$confirm('将封禁该号码, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(async () => {
      this.bannedUserDialogLoading = true;
      let reason = '';
      if (null != this.bannedForm.reason && this.bannedForm.reason.length > 0) {
        reason = `违规提醒：主叫${this.rowJson.telA}-被叫${this.rowJson.telB}，${this.bannedForm.reason}`;
      }
      if (null == this.bannedForm.disableDuration) {
        await modalConfirm('请选择禁用时长');
        return;
      }
      let params = {
        telA: this.rowJson.telA,
        checkId: this.rowJson.id,
        amountId: this.bannedForm.amountId,
        remark: this.bannedForm.remark,
        disableDuration: this.bannedForm.disableDuration,
        reason: reason,
      };
      let res = await safePromise(_lineService._recordService.addBlackA(params))
      if (res?.code == 200) {
        this.rowJson.telADisabled = 1;
        ElMessage({
          message: '号码封禁成功！',
          type: 'success',
        })
      }
      this.bannedUserDialogLoading = false;
      this.bannedUserDialog = false;
      // })
    },
    bannedUserOther() {
      this.$confirm('将封禁该号码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await _lineService._recordService.blackNumberAdd({
          blackNumberDtoList: [{
            number: this.rowJson.telB,
            checkId: this.rowJson.id
          }]
        })
        this.rowJson.telBDisabled = 1
        ElMessage({
          message: '号码封禁成功！',
          type: 'success',
        })
      })
    },
    copyMessage() {
      let input = document.createElement('textarea')
      input.style.opacity = '0'
      input.style.position = 'absolute'
      input.style.zIndex = '-1'
      document.body.append(input)
      input.value = this.readyToCopy
      input.select()
      let result = document.execCommand('Copy')
      if (result) {
        ElMessage({
          message: '复制成功',
          type: 'success',
        })
      } else {
        ElMessage({
          message: '复制失败',
          type: 'error',
        })
      }
      setTimeout(function () {
        document.body.removeChild(input)
      }, 50)
    },
    handelClose() {
      this.$emit('success')
    },
    addWorkOrder() {
      this.$emit('addWorkOrder')
      this.$emit('success')
    },
    async handelNext() {
      this.btnLoading = true
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      this.hitTypeDescList = []
      this.handelChange(1, null);
      // this.$emit('nextData', 1, values)
    },
    async handelUP() {
      this.btnLoading = true
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      // this.$emit('nextData', -1, values)
      this.handelChange(-1, null);
    },
    /**
     *
     * @param values 当前更新的值
     * @param page 1：下一条数据；0：当前不动，只是更新值；-1：上一条数据
     * @returns {Promise<void>}
     */
    async handelChange(page, values) {
      //接口调整，不再从接口取数据，直接从列表拿现有数据
      //与 record页面中handleRowClick方法兼容，中间三个参数给空值
      this.$emit('nextData', page, null, null, null, values)
    },
    async updateVoiceRisk(risk) {
      this.btnLoading = true
      try {
        await _lineService._recordService.updateVoiceRisk({
          checkId: this.rowJson.id,
          risk: risk
        })
        this.rowJson.risk = risk;
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        this.$emit('successDDD')
      } catch (err) {

      }
      this.btnLoading = false
    },
    async handelAutd() {
      this.btnLoading = true
      try {
        if (this.isAdminUser === 'web' || this.ruleType === 'api') {
          await _lineService._recordService.getVoiceUserCheck({
            checkId: this.rowJson.id,
            auditStatus: this.auditStatus
            // auditStatus: this.rowJson.auditStatus
          })
        } else {
          await _lineService._recordService.getVoiceCheck({
            checkId: this.rowJson.id,
            auditStatus: this.auditStatus
          })
        }
        this.rowJson.auditStatus = this.auditStatus;//
        this.auditStatus = 1;//重置为正常
        ElMessage({
          message: '审核成功',
          type: 'success',
        })
        // this.$emit('successDDD')
        let values = {auditStatus: this.rowJson.auditStatus};
        this.handelChange(1, values);
      } catch (err) {

      }
      this.btnLoading = false
    }
  }
};
</script>
<style lang="scss" scoped>
.menu {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.flex-nowrap-flex-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-nowrap-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.left-title {
  width: 120px;
  height: 45px;
  padding: 10px 0 10px 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text {
  width: 207px;
  height: 45px;
  color: #333333;
  padding: 10px 0 10px 15px;
  border: 1px solid #ebebeb;
  border-left: none;
}

.left-title-mid {
  width: 120px;
  height: 45px;
  padding: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text-mid {
  width: 207px;
  height: 45px;
  padding: 2px;
  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}

.left-title-other {
  width: 120px;
  height: 185px;
  line-height: 185px;
  padding-left: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}

.left-text-other {
  width: 207px;
  height: 185px;
  padding: 15px;
  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}

.bottom-box {
  height: 45px;
  border: 1px solid #ebebeb;
}

.recordSon-audio {
  width: 95%;
  height: 30px;
}

.talkBox {
  padding: 10px;
  width: 100%;
  overflow-y: overlay;
  height: 720px;
  background: #f3f3f3;
}

.float-right {
  float: right;
}

.text-align-left {
  padding-left: 65px;
  text-align: left;
}

.text-align-right {
  padding-right: 65px;
  text-align: right;
}

.telA-box {
  padding: 8px;
  background: #fff;
  border-radius: 6px;
}

.telB-box {
  padding: 8px;
  background: #9eea6a;
  border-radius: 6px;
}

.relative-box {
  position: relative;
}

.triangle {
  position: absolute;
  transform: rotate(-90deg);
  top: 39%;
  left: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffff transparent;
  width: 0px;
  height: 0px;
}

.triangle-other {
  position: absolute;
  transform: rotate(90deg);
  top: 39%;
  right: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}

.talkBox-item {
  padding: 2px 0;
}

.height-min {
  /* height: 500px; */
  overflow-y: overlay;
}

.right-width {
  margin-right: 10px;
  margin-bottom: 8px;
}

.overflow-y {
  overflow-y: overlay;
}

::v-deep(.el-table .cell) {
  line-height: 20px;
}

.flex-nowrap-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}

.voice-text {
  .voice-text-tooltip {
    font-size: 0.75rem;
  }

  .keywords {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}

//<a-radio :value="1">正常</a-radio>
//<a-radio :value="2">违规</a-radio>
//<a-radio :value="3">误判</a-radio>
//<a-radio :value="4">复审</a-radio>
//<a-radio :value="0">未审核</a-radio>

.audit-status-highest {
  border-radius: 4px;
  background: #fa5151;
  color: #FFFFFF;
}

.audit-status-high {
  border-radius: 4px;
  background: #ff8f1f;
  color: #FFFFFF;
}

.audit-status-mid {
  border-radius: 4px;
  background: #ffc300;
  color: #FFFFFF;
}

.audit-status-low {
  border-radius: 4px;
  background: #96b97d;
  color: #FFFFFF;
}

.audit-status-none {
  border-radius: 4px;
  background: #00b578;
  color: #FFFFFF;
}
</style>
