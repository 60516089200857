export const Key = 'cloud-key'

//http环境：'dev','test','production';
export const httpEnv = () => {
    if (/agent.dftx.develop/ig.test(process.env.VUE_APP_BASE_API)) {
        return 'dev';
    }
    if (/agent.dftx.test/ig.test(process.env.VUE_APP_BASE_API)) {
        return 'test';
    }
    return 'production';
};